import { ready, elementExists, setButtonState } from './utils';

ready(() => {
  const otpInput = document.querySelector('#otp-token-input');

  if (elementExists('otp')) {
    otpInput.addEventListener('keyup', e => {
      const TOKEN_MIN_LENGTH = 6;
      setButtonState(
        document.querySelector('#submit-btn'),
        e.target.value.length !== TOKEN_MIN_LENGTH
      );
    });

    otpInput.addEventListener('paste', e => {
      e.preventDefault();
      e.stopPropagation();
      const pastedText = (
        e.clipboardData ||
        window.clipboardData ||
        e.originalEvent.clipboardData
      ).getData('text');
      if (pastedText.length == 6) {
        otpInput.value = pastedText;
        setButtonState(document.querySelector('#submit-btn'), false);
      }
    });
  }
});
