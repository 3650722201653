import { ready } from './utils';

// Forwards the url parameters to all links present in the page.
// NOTE: WILL NOT HANDLE LINKS ADDED ON REACT COMPONENTS. Those need to be handled within react.

ready(() => {
  const params = window.location.search;
  document.querySelectorAll('a').forEach(element => {
    const link = element.getAttribute('href');
    if (link && link.match(/(.*tartle\.co.*|^\/.*)/)) {
      element.setAttribute('href', link + params);
    }
  });
});
